export function startWatchingSplitView() {
  document.addEventListener("DOMContentLoaded", () => {

    const handleGroup = (actor) => {
      const id = actor.dataset.switchTarget;
      const groupId = actor.dataset.switchGroup;
      let rows = document.querySelectorAll(`.ld-switch-target[data-switch-group="${groupId}"]`);
      rows.forEach(row => row.classList.add("ld-hidden"));
      rows = document.querySelectorAll(`.ld-switch-target[data-switch-target="${id}"]`);
      rows.forEach(row => row.classList.remove("ld-hidden"));

    };

    document.addEventListener("click", function(event) {
      let actor;
      if (actor = event.target.closest(".ld-switch-actor")) {
        const id = actor.dataset.switchTarget;
        const rows = document.querySelectorAll(`.ld-switch-target[data-switch-target="${id}"]`);
        rows.forEach(row => row.classList.toggle("ld-hidden"));
      }
      if (actor = event.target.closest(".ld-switch-group-actor")) {
        handleGroup(actor);
      }
    });

    document.addEventListener("change", function(event) {
      const actor = event.target;
      if (actor.matches("input.ld-switch-group-actor[type=\"radio\"]")) {
        handleGroup(actor);
      }
    });
  });
}
