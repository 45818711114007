import Sortable from "sortablejs";

function generateRandomString(length = 10) {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";

  for (let i = 0; i < length; i++) {
    // Generate a random index to pick a character from the characters string
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters[randomIndex];
  }

  return result;
}

export function startSimpleReorder() {

  const controller = document.querySelector(".ld-simple-nested-sortable");
  if (!controller) {
    return;
  }
  const attrib = controller.getAttribute("data-attrib");
  const container = document.querySelector(".nested-fields");
  const itemClass = ".ld-metadata-template-item-fields";
  Sortable.create(container, {
    draggable: itemClass,
    handle: ".ld-drag-handle",
    // Called when dragging element changes position
    onChange: function(/**Event*/evt) {
      console.log(evt, "->", evt.newIndex); // most likely why this event is used is to get the dragging element's current index
      // same properties as onEnd
      container.querySelectorAll(itemClass).forEach((item, index) => {
        item.querySelector(".metadata_template_items_priority input").value = index;
      });
    }
  });

  document.querySelector(".nested-fields").addEventListener("cocoon:after-insert", (e) => {
    const addedItem = e.detail[2];
    addedItem.querySelector(".metadata_template_items_priority input").value = container.querySelectorAll(itemClass).length;
    addedItem.querySelector(".metadata_template_items_key input").value = generateRandomString();
  });

}
