import { createChatChannel } from "../channels/chat_channel";
import { smartFormatDatetime } from "../lib/date";

document.addEventListener("DOMContentLoaded", () => {

  const controller = document.querySelector(".ld-chat-controller");
  if (!controller) {
    return;
  }

  const pageId = controller.getAttribute("data-page-id");

  const chatWindow = document.querySelector(".ld-chat-window");
  const chatBody = document.querySelector(".ld-chat-body");
  const container = chatBody.querySelector(".ui.feed");
  const inputField = document.querySelector(".ld-chat-message-field");
  const sendButton = document.querySelector(".ld-chat-send-message-button");
  const template = document.querySelector("#ld-chat-event-template");
  const chatCounter = document.querySelector(".ld-chat-message-label");
  const chatButton = document.querySelector(".ld-chat-button");
  const chatButtonButton = document.querySelector(".ld-chat-button .ui.labeled.button .ui.button");
  const chatCloseButton = document.querySelector(".ld-chat-close");

  if (!pageId || !container || !inputField || !sendButton || !template || !chatButton || !chatButtonButton || !chatWindow || !chatCloseButton) {
    console.error("Chat initialization failed: Missing elements.");
    return;
  }

  chatButton.addEventListener("click", () => {
    // chatCounter.innerText = '0';

    chatButtonButton.classList.remove("red");
    chatCounter.classList.remove("red");

    chatWindow.classList.add("show");

    inputField.focus();
  });

  chatCloseButton.addEventListener("click", () => {
    chatWindow.classList.remove("show");
  });

  const isChatVisible = () => {
    return chatWindow.classList.contains("show");
  };

  const increaseCounter = () => {

    let counter = parseInt(chatCounter.innerText, 10);
    counter += 1;
    chatCounter.innerText = counter;

    if (isChatVisible()) {
      return;
    }

    chatButtonButton.classList.add("red");
    chatCounter.classList.add("red");
  };

  createChatChannel(pageId)
    .onConnect(channel => {

      document.querySelector("#ld-chat").style.visibility = "visible";

      const sendMessage = () => {
        const message = inputField.value.trim();
        if (message !== "") {
          channel.send(message);
        }
        inputField.value = "";
        inputField.focus();
      };

      sendButton.addEventListener("click", sendMessage);

      inputField.addEventListener("keydown", (event) => {
        if (event.key === "Enter") {
          sendMessage();
        }
      });
    })
    .onMessage((channel, text, user, date) => {

      increaseCounter();

      const clone = template.content.cloneNode(true);

      clone.querySelector(".ldt-username").textContent = user.name;
      clone.querySelector(".ldt-text").textContent = text;
      clone.querySelector(".ldt-avatar").src = user.avatar;
      const formattedDate = smartFormatDatetime(date);
      clone.querySelector(".ldt-date").replaceWith(formattedDate);

      container.appendChild(clone);
      chatBody.scrollTop = chatBody.scrollHeight;
    });
});
