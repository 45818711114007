import Quill from "quill";
const Inline = Quill.import("blots/inline");
const Block = Quill.import("blots/block");
const BubbleTheme = Quill.import("themes/bubble");
const { generateUUID } = require("../lib/uuid");
const Embed = Quill.import("blots/embed");

class InternalLink extends Inline {
  static create(value) {
    const node = super.create(value);
    if (!value.match(/^page:\/\//)) {
      value = `page://${value}`;
    }
    node.setAttribute("href", value);
    node.setAttribute("data-original-link", value);
    return node;
  }

  static formats(domNode) {
    return domNode.getAttribute("data-original-link");
  }
}

InternalLink.blotName = "internal_link";
InternalLink.className = "ldx-internal-link";
InternalLink.tagName = "A";

Quill.register(InternalLink);

class CommentSidebarEntry extends Inline {
  static create(value) {
    console.log("create sidebar with", value);
    if (value === true) {
      value = generateUUID();
    }
    console.log("create sidebar with, v2: ", value);
    const node = super.create(value);
    if (!value.match(/^comment:\/\//)) {
      value = `comment://${value}`;
    }
    console.log("create sidebar with, v3: ", value);
    node.setAttribute("data-comment-id", value);
    return node;
  }

  static formats(domNode) {
    return domNode.getAttribute("data-comment-id");
  }
}

CommentSidebarEntry.blotName = "comment_sidebar_entry";
CommentSidebarEntry.className = "ldx-sidebar-entry";
CommentSidebarEntry.tagName = "SPAN";

Quill.register(CommentSidebarEntry);

class SummaryPara extends Block {
  static create(value) {
    const node = super.create();
    if (!value.id.match(/^page:\/\//)) {
      value.id = `page://${value}`;
    }
    node.setAttribute("data-page-id", value.id);
    node.setAttribute("data-summary-length", value.target);
    node.setAttribute("data-page-title", value.title);
    node.setAttribute("data-summary-focus", value.focus);
    node.setAttribute("contenteditable", "false");
    return node;
  }

  static formats(node) {
    return {
      id: node.getAttribute("data-page-id"),
      target: node.getAttribute("data-summary-length"),
      title: node.getAttribute("data-page-title"),
      focus: node.getAttribute("data-summary-focus")
    };
  }
}

SummaryPara.blotName = "summary_para";
SummaryPara.className = "ldx-summary";
SummaryPara.tagName = "P";

Quill.register(SummaryPara);

class EmbedPara extends Block {
  static create(value) {
    const node = super.create();
    if (!value.id.match(/^page:\/\//)) {
      value.id = `page://${value}`;
    }
    node.setAttribute("data-page-id", value.id);
    node.setAttribute("contenteditable", "false");
    return node;
  }

  static formats(node) {
    return {
      id: node.getAttribute("data-page-id"),
    };
  }
}

EmbedPara.blotName = "embed_para";
EmbedPara.className = "ldx-embed";
EmbedPara.tagName = "DIV";

Quill.register(EmbedPara);

class CustomBR extends Embed {};
CustomBR.blotName = "custombr";
CustomBR.tagName = "span";
CustomBR.className = "ld-custombr";
Quill.register(CustomBR);

class SplitHR extends Block {};
SplitHR.blotName = "splithr";
SplitHR.tagName = "hr";
SplitHR.className = "ld-splithr";
Quill.register(SplitHR);
