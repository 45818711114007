const { findCSRF } = require("./csrf");

export function createJSONQuery(data, method = "POST") {
  return {
    method: method,
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
      "X-CSRF-Token": findCSRF()
    },
    body: JSON.stringify(data)
  };
}
