import canvasDatagrid from "@28dab/canvas-datagrid";
import { svg } from "../lib/svg";

function getSheetData() {
  return window.sheetData;
}

function transformSheetData(input) {
  return input.data.map((row, rowIndex) => {
    const rowData = {};
    input.columns.forEach((col, colIndex) => {
      rowData[col.name] = row[colIndex] || "";
    });
    return rowData;
  });
}

function transformSchema(schema) {
  return schema.map(column => {
    return {
      name: column.name,
      title: column.name,
      width: column.width || 120,
      type: column.type || "string",
      options: column.options
    };
  });
}

function transformColumnData(input) {
  return transformSchema(input.columns);
}

function getColumnsWidths(schema, grid) {
  return schema.map((column, index) => {
    const dup =  { ...column };
    dup["width"] = grid.getColumnWidth(index);
    return dup;
  });
}

function renameField(array, from, to) {
  return array.map(row => {
    const dup = { ...row };
    dup[to] = dup[from];
    delete dup[from];
    return dup;
  });
}

function saveSheetData(grid) {
  const columns = grid.schema.map((col, colIndex) => {
    return {
      name: col.title ?? col.name,
      type: col.type,
      width: grid.getColumnWidth(colIndex),
      options: col.options,
    };
  });
  const keys = grid.schema.map(col => col.name);
  const data = grid.data.map(row => {
    return keys.map(key => row[key]);
  });
  return {
    data: data,
    columns: columns,
    sheetData: gatherSheetData()
  };
}

function sheetSvg(icon, title) {
  return `<div class="ld-canvas-context-menu-item">${svg(icon, title)}</div>`;
}

const DEFAULT_WIDTH = 120;

function appendAndSubmit(form, sheet) {
  const hiddenField = document.createElement("input");
  hiddenField.type = "hidden";
  hiddenField.name = "page[html]";
  hiddenField.value = JSON.stringify(sheet);

  const formatField = document.createElement("input");
  formatField.type = "hidden";
  formatField.name = "page[target_format]";
  formatField.value = "sheet";

  form.appendChild(hiddenField);
  form.appendChild(formatField);

  form.submit();
}

function setupSheetForm(sheetDataForm, sheetData) {
  // Helper function to create input fields
  const createInputField = (labelText, value, inputName) => {
    // Create a wrapper div for the field
    const fieldWrapper = document.createElement("div");
    fieldWrapper.className = "ui field"; // Semantic UI field class

    // Create the label element
    const label = document.createElement("label");
    label.textContent = labelText; // Set the label text

    // Create the input element
    const input = document.createElement("input");
    input.type = "text"; // Default type, can be changed
    input.name = inputName; // Set the name attribute
    input.placeholder = `Enter ${labelText}`; // Placeholder text
    input.value = value ?? "";

    // Append label and input to the wrapper
    fieldWrapper.appendChild(label);
    label.appendChild(input);

    return fieldWrapper; // Return the constructed field
  };

  // Clear existing fields if needed
  sheetDataForm.innerHTML = "";  // Clear the form before adding new fields

  // Check and create a field for populateWith
  //    if (sheetData.populateWith) { // Ensure it's not null
  const populateField = createInputField("Populate with children of", sheetData.populateWith, "populate_with");
  sheetDataForm.appendChild(populateField); // Append to the form
  //    }

  // Check and create a field for groupBy
  //    if (sheetData.groupBy) { // Ensure it's not null
  const groupField = createInputField("Group by", sheetData.groupBy, "group_by");
  sheetDataForm.appendChild(groupField); // Append to the form
//    }
}

function gatherSheetData() {
  const findValue = function (inputName) {
    const inputElement = document.querySelector(`input[name="${inputName}"]`); // Select input by name
    return inputElement ? inputElement.value : null; // Return the value or null if not found
  };

  return {
    populateWith: findValue("populate_with"),
    groupBy: findValue("group_by"),
  };
}
function isObject(obj) {
  return obj && typeof obj === "object" && !Array.isArray(obj);
}

export function startWatchingSheets() {

  const controller = document.querySelector(".ld-sheet-controller");
  if (!controller) {
    return;
  }

  const columnsView = document.querySelector(".ld-sheet-columns");

  const input = getSheetData();

  //showNewRow: true,
  // allowFreezingRows: true,

  const grid = canvasDatagrid({
    data: transformSheetData(input),
    schema: transformColumnData(input),
    allowColumnReordering: false,
    allowRowReordering: true,
    showCopy: true,
    showPaste: true,
  });

  grid.formatters.reference = function (e) {
    return e.cell.value;
  };

  grid.formatters.optional_reference = function (e) {
    return e.cell.value;
  };

  grid.formatters.metadata = function (e) {
    const v = e.cell.value;
    if (isObject(v)) {
      if (v.html) {
        return v.html;
      }
      return v;
    }
    return e.cell.value;

  };

  grid.style.height = "400px";
  grid.style.width = "100%";

  const newColumnName = () => `Column ${grid.schema.length + 1}`;

  function isInvalid(value) {
    return value === null || value === undefined || value === Infinity || value === -Infinity || isNaN(value);
  }

  const selectedColumns = (cell=null, deleteFunc=false) => {
    if (cell != null) {
      return {
        first: cell.column,
        last: cell.column,
        count: 1
      };
    }
    const bounds = grid.selectionBounds;
    if (isInvalid(bounds.left)) {
      const active = grid.activeCell;
      if (isInvalid(active.columnIndex)) {
        return deleteFunc ? {
          first: 0,
          last: -1,
          count: 0
        } : {
          first: 0,
          last: grid.schema.length - 1,
          count: grid.schema.length
        };
      }
      return {
        first: active.columnIndex,
        last: active.columnIndex,
        count: 1
      };
    }
    return {
      first: bounds.left,
      last: bounds.right,
      count: bounds.right - bounds.left + 1
    };
  };

  const selectedRows = (cell=null, deleteFunc=false) => {
    console.log("@selectedRows", "cell", cell, "deleteFunc", deleteFunc);
    if (cell != null) {
      return {
        first: cell.row,
        last: cell.row,
        count: 1
      };
    }
    const bounds = grid.selectionBounds;
    console.log("@SR bounds", bounds);
    if (isInvalid(bounds.top)) {
      const active = grid.activeCell;
      console.log("@SR active", active);
      if (isInvalid(active.rowIndex)) {
        return deleteFunc ? {
          first: 0,
          last: -1,
          count: 0
        } : {
          first: 0,
          last: grid.data.length - 1,
          count: grid.data.length
        };
      }
      return {
        first: active.rowIndex,
        last: active.rowIndex,
        count: 1
      };
    }
    return {
      first: bounds.top,
      last: bounds.bottom,
      count: bounds.bottom - bounds.top + 1
    };
  };

  const func = {};
  func.columnDelete =  (e=null, cell=null) => {
    const columns = selectedColumns(cell, true);
    for (let i = 0; i < columns.count; i++) {
      grid.deleteColumn(columns.first);
    }
  };
  func.rowDelete = (e=null, cell=null) => {
    const rows = selectedRows(cell, true);
    for (let i = 0; i < rows.count; i++) {
      grid.deleteRow(rows.first);
    }
  };
  func.columnLeft = (e=null, cell=null) => {
    const columns = selectedColumns(cell);
    grid.insertColumn({ name: newColumnName() }, columns.first);
  };
  func.columnRight = (e=null, cell=null) => {
    const columns = selectedColumns(cell);
    grid.insertColumn({ name: newColumnName() }, columns.last + 1);
  };
  func.rowAbove = (e=null, cell=null) => {
    const rows = selectedRows(cell);
    grid.insertRow({}, rows.first);
  };
  func.rowBelow = (e=null, cell=null) => {
    const rows = selectedRows(cell);
    grid.insertRow({}, rows.last + 1);
  };

  const createDrawFunc = (cell) => {
    const ob = {};
    for (const [key, originalFunc] of Object.entries(func)) {
      ob[key] = (e) => {
        originalFunc(e, cell);
        grid.draw();
      };
    }
    return ob;
  };


  grid.addEventListener("contextmenu", function (e) {
    const column = e.cell.columnIndex;
    const row = e.cell.rowIndex;
    const normal = e.cell.isNormal;
    const rowHeader = e.cell.isRowHeader;
    const allRows = grid.data.length;
    const drawFunc = createDrawFunc({ row:row, column:column });
    console.log(e.cell, "c", column, "r", row, "allRows", allRows, "normal", normal, "rh", rowHeader);
    e.items.splice(0, e.items.length);
    if (row >= 0 && row <= allRows) {
      e.items.push({
        title: sheetSvg("row-above", "Insert row above"),
        click: drawFunc.rowAbove
      });
    }
    if (row >= 0 && row < allRows) {
      e.items.push({
        title: sheetSvg("row-below", "Insert row below"),
        click: drawFunc.rowBelow
      });
    }
    if (row >= 0) {
      e.items.push({
        title: sheetSvg("delete-row", "Delete row"),
        click: drawFunc.rowDelete
      });
    }
    if (column >= 0) {
      e.items.push({
        title: sheetSvg("column-right", "Insert column right"),
        click: drawFunc.columnRight
      });
      e.items.push({
        title: sheetSvg("column-left", "Insert column left"),
        click:drawFunc.columnLeft
      });
      e.items.push({
        title: sheetSvg("delete-column", "Delete column"),
        click: drawFunc.columnDelete
      });
    }
  });

  // console.log("grid col 0",grid.getColumnWidth(0));

  controller.appendChild(grid);

  grid.attributes.selectionFollowsActiveCell = true;
  grid.attributes.columnHeaderClickBehavior = "select";
  grid.style.columnHeaderCellHorizontalAlignment = "center";
  grid.style.contextMenuBackground = "white";
  grid.style.contextMenuFontFamily = "Merriweather";

  input.columns.forEach((col, colIndex) => {
    grid.setColumnWidth(colIndex, col.width ?? DEFAULT_WIDTH);
  });

  const template = document.querySelector("#ld-column-template");

  const setupSchema = () => {
    console.log("setupSchema");
    columnsView.replaceChildren();
    grid.schema.forEach((col, colIndex) => {
      const clone = template.content.cloneNode(true);
      const head = clone.querySelector(".ld-column-template");
      head.setAttribute("data-column", colIndex);
      const nameField = head.querySelector("input[name=name]");
      nameField.value = col.name;
      nameField.addEventListener("input", e => {
        grid.schema[colIndex].title = nameField.value;
        grid.draw();
      });
      const widthField = head.querySelector("input[name=width]");
      widthField.value = col.width ?? DEFAULT_WIDTH;
      widthField.addEventListener("input", e => {
        grid.setColumnWidth(colIndex, widthField.value);
      });
      const typeField = head.querySelector("select[name=type]");
      console.log("set Column Type", colIndex, "->", col.type);
      typeField.value = col.type ?? "string";
      typeField.addEventListener("change", e => {
        grid.schema[colIndex].type = typeField.value;
      });
      const optionsField = head.querySelector("input[name=options]");
      optionsField.value = col.options ?? "";
      optionsField.addEventListener("input", e => {
        grid.schema[colIndex].options = optionsField.value;
      });
      columnsView.appendChild(clone);
    });
  };

  grid.addEventListener("schemachanged", setupSchema);
  grid.addEventListener("reorder", setupSchema);
  setupSchema();

  grid.addEventListener("resize", function(e) {
    console.log("@resize");
    grid.schema.forEach((col, colIndex) => {
      const head = document.querySelector(`.ld-column-template[data-column="${colIndex}"]`);
      head.querySelector("input[name=width]").value = grid.getColumnWidth(colIndex);
    });
  });

  document.querySelector(".ld-save-group button").addEventListener("click", (e) => {
    // const xxx = document.querySelector(".sheetdata");
    e.preventDefault();
    const sheetData = saveSheetData(grid);
    const form = document.querySelector(".ld-sheet-form form");
    appendAndSubmit(form, sheetData);
  });

  document.querySelector(".ld-column-delete-button").addEventListener("click", func.columnDelete);
  document.querySelector(".ld-row-delete-button").addEventListener("click", func.rowDelete);
  document.querySelector(".ld-column-left-button").addEventListener("click", func.columnLeft);
  document.querySelector(".ld-column-right-button").addEventListener("click", func.columnRight);
  document.querySelector(".ld-row-above-button").addEventListener("click", func.rowAbove);
  document.querySelector(".ld-row-below-button").addEventListener("click", func.rowBelow);

  const sheetDataForm = document.querySelector(".ld-sheet-sheetdata");
  setupSheetForm(sheetDataForm, input.sheetData ?? {});

  // console.log("grid col 1",grid.getColumnWidth(0));

  // const columnsGrid = canvasDatagrid({
  //   data: transformColumnData(input),
  //   allowColumnReordering: false,
  //   allowRowReordering: true,
  //   showCopy: true,
  //   showPaste: true,
  // });

  // columnsView.appendChild(columnsGrid);
  // [0, 1, 2].forEach(n => {
  //   columnsGrid.setColumnWidth(n, 120);
  // });

  // console.log("grid col",grid.getColumnWidth(0));

  // grid.addEventListener('schemachanged', e => {
  //   console.log("schema changed!", e.schema);
  //   columnsGrid.data = transformSchema(getColumnsWidths(e.schema, grid));
  // });

  // columnsGrid.addEventListener('endedit', e => {
  //   if (e.cell.columnIndex == 0) {
  //     let previousName = grid.schema[e.cell.rowIndex].name;
  //     console.log("column rename",e.cell,e.value,"previous",previousName);
  //     grid.data = renameField(grid.data, previousName, e.value);
  //   }
  //   grid.schema = columnsGrid.data;
  // });
}
