import { createConversationChannel } from "../channels/conversation_channel";
import { createJSONQuery } from "../lib/json";
import { smartFormatDatetime } from "../lib/date";

export function startWatchingConversation() {
  const controller = document.querySelector(".ld-conversation-controller");
  if (!controller) {
    return;
  }
  const conversationId = controller.dataset.conversationId;
  const conversationUrl = controller.dataset.conversationUrl;
  console.log("load conversation", conversationId);

  let result = "";

  const messageTemplate = document.querySelector("template#ld-conversation-message-template");
  const conversationList = document.querySelector(".ld-conversation-list");

  createConversationChannel(conversationId)
    .onChunk(text => {
      console.log("chunk", text);
      result += text;
      // document.querySelector(".debug-box").innerHTML = result;
    })
    .onStatus(status => {
      console.log("status", status);
      result += `<p>${status}</p>`;
      document.querySelector(".debug-box").innerHTML = result;
    })
    .onNewTool(tool => {
      console.log("tool MSG!", tool);
      // document.querySelector(".debug-box").innerHTML = message;

      const messageNode = messageTemplate.content.cloneNode(true);

      // console.log("cloned node:",messageNode,messageNode.outerHTML);
      // var content = messageNode.querySelector('.ldt-content');
      // console.log("content",content);
      messageNode.querySelector(".ldt-content").innerHTML = tool;
      messageNode.querySelector(".ldt-username").textContent = "Tool";
      // messageNode.querySelector('.ldt-time').appendChild(smartFormatDatetime(message.createdAt));
      // messageNode.querySelector('.ldt-image').setAttribute('src', message.avatar);
      // messageNode.querySelector('.ldt-message-id').textContent = message.id;

      conversationList.appendChild(messageNode);
    })
    .onNewMessage(message => {
      console.log("MSG!", message);
      // document.querySelector(".debug-box").innerHTML = message;

      const messageNode = messageTemplate.content.cloneNode(true);

      // console.log("cloned node:",messageNode,messageNode.outerHTML);
      // var content = messageNode.querySelector('.ldt-content');
      // console.log("content",content);
      messageNode.querySelector(".ldt-content").innerHTML = message.textContent;
      messageNode.querySelector(".ldt-username").textContent = message.username;
      messageNode.querySelector(".ldt-time").appendChild(smartFormatDatetime(message.createdAt));
      messageNode.querySelector(".ldt-image").setAttribute("src", message.avatar);
      messageNode.querySelector(".ldt-message-id").textContent = message.id;

      conversationList.appendChild(messageNode);
    })
    .onFinished(() => {
      console.log("finished ws");
      // document.querySelector(".ld-editing-buttons").classList.remove("ld-hidden");
      // document.querySelector(".ld-merge-loader").classList.remove("active");
      // createEditingDiff();
    })
    .onConnect(channel => {
      console.log("got prop ws!");

      const query = createJSONQuery({}, "POST");

      fetch(conversationUrl, query)
        .then(response => {
          console.log("response: ", response);
          return response.json();
        })
        .then(result => {
          console.log("result->", result);
        })
        .catch(error => {
          console.error("Error:", error);
        });
    });
}
