import { createProposalChannel } from "../channels/proposal_channel";
import { createJSONQuery } from "../lib/json";
import { createEditingDiff } from "./diff";

export function startWatchingProposals() {
  const controller = document.querySelector(".ld-proposal-controller");
  if (!controller) {
    return;
  }
  const proposalId = controller.dataset.proposalId;
  const proposalUrl = controller.dataset.proposalUrl;
  console.log("load proposal", proposalId);

  let result = "";

  createProposalChannel(proposalId)
    .onChunk(text => {
      result += text;
      document.querySelector(".ld-diff-target").innerHTML = result;
    })
    .onFinished(() => {
      console.log("finished ws");
      document.querySelector(".ld-editing-buttons").classList.remove("ld-hidden");
      document.querySelector(".ld-merge-loader").classList.remove("active");
      createEditingDiff();
    })
    .onConnect(channel => {
      console.log("got prop ws!");

      const query = createJSONQuery({}, "POST");

      fetch(proposalUrl, query)
        .then(response => {
          console.log("response: ", response);
          return response.json();
        })
        .then(result => {
          console.log("result->", result);
        })
        .catch(error => {
          console.error("Error:", error);
        });
    });
}
