import Sortable from "sortablejs";
const { createJSONQuery } = require("../lib/json");

export function startHandlingReorder() {
  document.addEventListener("DOMContentLoaded", () => {
    const controller = document.querySelector(".ld-reorder-controller");
    if (!controller) {
      return;
    }
    const url = controller.getAttribute("data-url");
    console.log("reorder setup reorder", url);

    const sortContainer = document.querySelector(".ld-reorderable");
    const dimmer = sortContainer.querySelector(".ui.dimmer");
    const sortable = Sortable.create(sortContainer, {
      handle: ".ld-drag-handle",
      onEnd: function (/**Event*/evt) {
        const itemEl = evt.item;  // dragged HTMLElement
        // evt.to;    // target list
        // evt.from;  // previous list
        // evt.oldIndex;  // element's old index within old parent
        // evt.newIndex;  // element's new index within new parent
        // evt.oldDraggableIndex; // element's old index within old parent, only counting draggable elements
        // evt.newDraggableIndex; // element's new index within new parent, only counting draggable elements
        // evt.clone // the clone element
        // evt.pullMode;  // when item is in another sortable: `"clone"` if cloning, `true` if moving

        const newOrder = Array.from(sortContainer.querySelectorAll(".item")).map(item => item.getAttribute("data-page-id"));
        console.log("order:", newOrder);

        const data = { order:newOrder };

        dimmer.classList.add("active");

        console.log("fetch", data);
        fetch(url, createJSONQuery(data))
          .then(response => response.json()) // Parse JSON response
          .then(result => {
            console.log("got result:", result);
            dimmer.classList.remove("active");
          })
          .catch(error => {
            console.error("Error:", error);
          });



        //.forEach((item, index) => {

        //    });
      } });

  });
}
