import consumer from "./consumer";

export function createConversationChannel(conversation_id) {
  return {
    object: null,
    callbacks: {
      onConnect: null,
      onChunk: null,
      onFinished: null,
      onNewMessage: null,
      onNewTool: null,
      onStatus: null,
    },
    send: function(msg) {
      this.object.send({ body: msg });
    },
    onConnect(callback) {

      const channel = this;

      this.callbacks.onConnect = callback;

      let lastSentChunk = 0;
      const chunks = {};

      this.object = consumer.subscriptions.create({ conversation_id: conversation_id, channel: "ConversationChannel" }, {
        received(data) {
          console.log("received", data);
          if (data["type"] == "chunk") {
            chunks[data.seq] = data.body; //}
            if (channel.callbacks.onChunk) {
              while (true) {
                const index = lastSentChunk + 1;
                const ob = chunks[index];
                if (index in chunks) {
                  channel.callbacks.onChunk(ob);
                  delete chunks[lastSentChunk + 1];
                  lastSentChunk++;
                } else {
                  break;
                }
              }
            }
          }
          if (data["type"] == "finished" && channel.callbacks.onFinished) {
            channel.callbacks.onFinished();
          }
          if (data["type"] == "new_message" && channel.callbacks.onNewMessage) {
            channel.callbacks.onNewMessage(data["message"]);
          }
          if (data["type"] == "tool" && channel.callbacks.onNewTool) {
            channel.callbacks.onNewTool(data["tool"]);
          }
          if (data["type"] == "status" && channel.callbacks.onStatus) {
            channel.callbacks.onStatus(data["status"]);
          }
        },
        connected() {
          console.log("WS connected");
          if (channel.callbacks.onConnect) {
            channel.callbacks.onConnect(channel);
          }
        },

        disconnected() {
          console.log("WS disconnected");
        },
      });

      return this;
    },
    onChunk(callback) {
      this.callbacks.onChunk = callback;
      return this;
    },
    onNewMessage(callback) {
      this.callbacks.onNewMessage = callback;
      return this;
    },
    onFinished(callback) {
      this.callbacks.onFinished = callback;
      return this;
    },
    onNewTool(callback) {
      this.callbacks.onNewTool = callback;
      return this;
    },
    onStatus(callback) {
      this.callbacks.onStatus = callback;
      return this;
    }
  };
}
