const SVG = {
  "column-left": "<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"-5 -5 24 24\" width=\"28\" fill=\"currentColor\"><path d=\"M8,12 L8,2 L2,2 L2,3 C2,3.55228475 1.55228475,4 1,4 C0.44771525,4 0,3.55228475 0,3 L0,2 C0,0.8954305 0.8954305,0 2,0 L12,0 C13.1045695,0 14,0.8954305 14,2 L14,12 C14,13.1045695 13.1045695,14 12,14 L2,14 C0.8954305,14 0,13.1045695 0,12 L0,11 C0,10.4477153 0.44771525,10 1,10 C1.55228475,10 2,10.4477153 2,11 L2,12 L8,12 Z M10,12 L12,12 L12,10 L10,10 L10,12 Z M12,8 L12,6 L10,6 L10,8 L12,8 Z M12,4 L12,2 L10,2 L10,4 L12,4 Z M3,6 L3,5 C3,4.44771525 3.44771525,4 4,4 C4.55228475,4 5,4.44771525 5,5 L5,6 L6,6 C6.55228475,6 7,6.44771525 7,7 C7,7.55228475 6.55228475,8 6,8 L5,8 L5,9 C5,9.55228475 4.55228475,10 4,10 C3.44771525,10 3,9.55228475 3,9 L3,8 L2,8 C1.44771525,8 1,7.55228475 1,7 C1,6.44771525 1.44771525,6 2,6 L3,6 Z\"></path></svg>",
  "column-right": "<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"-5 -5 24 24\" width=\"28\" fill=\"currentColor\"><path d=\"M12,0 C13.1045695,0 14,0.8954305 14,2 L14,3 C14,3.55228475 13.5522847,4 13,4 C12.4477153,4 12,3.55228475 12,3 L12,2 L6,2 L6,12 L12,12 L12,11 C12,10.4477153 12.4477153,10 13,10 C13.5522847,10 14,10.4477153 14,11 L14,12 C14,13.1045695 13.1045695,14 12,14 L2,14 C0.8954305,14 0,13.1045695 0,12 L0,2 C0,0.8954305 0.8954305,0 2,0 L12,0 Z M4,10 L2,10 L2,12 L4,12 L4,10 Z M10,4 C10.5522847,4 11,4.44771525 11,5 L11,6 L12,6 C12.5522847,6 13,6.44771525 13,7 C13,7.55228475 12.5522847,8 12,8 L11,8 L11,9 C11,9.55228475 10.5522847,10 10,10 C9.44771525,10 9,9.55228475 9,9 L9,8 L8,8 C7.44771525,8 7,7.55228475 7,7 C7,6.44771525 7.44771525,6 8,6 L9,6 L9,5 C9,4.44771525 9.44771525,4 10,4 Z M4,6 L2,6 L2,8 L4,8 L4,6 Z M4,2 L2,2 L2,4 L4,4 L4,2 Z\"></path></svg>",
  "row-above": "<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"-5 -5 24 24\" width=\"28\" fill=\"currentColor\"><path d=\"M12,8 L12,2 L11,2 C10.4477153,2 10,1.55228475 10,1 C10,0.44771525 10.4477153,0 11,0 L12,0 C13.1045695,0 14,0.8954305 14,2 L14,12 C14,13.1045695 13.1045695,14 12,14 L2,14 C0.8954305,14 0,13.1045695 0,12 L0,2 C0,0.8954305 0.8954305,0 2,0 L3,0 C3.55228475,0 4,0.44771525 4,1 C4,1.55228475 3.55228475,2 3,2 L2,2 L2,8 L12,8 Z M12,10 L10,10 L10,12 L12,12 L12,10 Z M8,12 L8,10 L6,10 L6,12 L8,12 Z M4,12 L4,10 L2,10 L2,12 L4,12 Z M6,3 L6,2 C6,1.44771525 6.44771525,1 7,1 C7.55228475,1 8,1.44771525 8,2 L8,3 L9,3 C9.55228475,3 10,3.44771525 10,4 C10,4.55228475 9.55228475,5 9,5 L8,5 L8,6 C8,6.55228475 7.55228475,7 7,7 C6.44771525,7 6,6.55228475 6,6 L6,5 L5,5 C4.44771525,5 4,4.55228475 4,4 C4,3.44771525 4.44771525,3 5,3 L6,3 Z\"></path></svg>",
  "row-below": "<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"-5 -5 24 24\" width=\"28\" fill=\"currentColor\"><path d=\"M2,6 L2,12 L3,12 C3.55228475,12 4,12.4477153 4,13 C4,13.5522847 3.55228475,14 3,14 L2,14 C0.8954305,14 0,13.1045695 0,12 L0,2 C0,0.8954305 0.8954305,0 2,0 L12,0 C13.1045695,0 14,0.8954305 14,2 L14,12 C14,13.1045695 13.1045695,14 12,14 L11,14 C10.4477153,14 10,13.5522847 10,13 C10,12.4477153 10.4477153,12 11,12 L12,12 L12,6 L2,6 Z M2,4 L4,4 L4,2 L2,2 L2,4 Z M6,2 L6,4 L8,4 L8,2 L6,2 Z M10,2 L10,4 L12,4 L12,2 L10,2 Z M8,11 L8,12 C8,12.5522847 7.55228475,13 7,13 C6.44771525,13 6,12.5522847 6,12 L6,11 L5,11 C4.44771525,11 4,10.5522847 4,10 C4,9.44771525 4.44771525,9 5,9 L6,9 L6,8 C6,7.44771525 6.44771525,7 7,7 C7.55228475,7 8,7.44771525 8,8 L8,9 L9,9 C9.55228475,9 10,9.44771525 10,10 C10,10.5522847 9.55228475,11 9,11 L8,11 Z\"></path></svg>",
  "delete-column": "<svg xmlns=\"http://www.w3.org/2000/svg\" fill=\"#000000\" width=\"28\" height=\"28\" viewBox=\"0 0 32 32\"><polygon points=\"30 3.41 28.59 2 25 5.59 21.41 2 20 3.41 23.59 7 20 10.59 21.41 12 25 8.41 28.59 12 30 10.59 26.41 7 30 3.41\"/><path d=\"M24,14V28H18V6H16V28a2.0023,2.0023,0,0,0,2,2h6a2.0023,2.0023,0,0,0,2-2V14Z\"/><path d=\"M10,30H4a2.0021,2.0021,0,0,1-2-2V8A2.0021,2.0021,0,0,1,4,6h6a2.0021,2.0021,0,0,1,2,2V28A2.0021,2.0021,0,0,1,10,30ZM4,8V28h6V8Z\"/></svg>",
  "delete-row": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"28\" height=\"28\" viewBox=\"0 0 32 32\"><path fill=\"currentColor\" d=\"M24 30H4a2 2 0 0 1-2-2v-6a2 2 0 0 1 2-2h20a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2M4 22h-.002L4 28h20v-6zM30 3.41L28.59 2L25 5.59L21.41 2L20 3.41L23.59 7L20 10.59L21.41 12L25 8.41L28.59 12L30 10.59L26.41 7z\"/><path fill=\"currentColor\" d=\"M4 14V8h14V6H4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h22v-2Z\"/></svg>",
};

export function svg(icon, title) {
  return `<div class="ldjs-svg-block">${SVG[icon]}<span>${title}</span></div>`;
}
