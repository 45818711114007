const htmlparser2 = require("htmlparser2");
const Diff = require("diff");

const DEBUG = false;

function __debug(...arg) {
  if (DEBUG) {
    console.log(...arg);
  }
}
function __debug_table(...arg) {
  if (DEBUG) {
    console.table(...arg);
  }
}

class ExtendedChunk {
  constructor(text, attributes = []) {
    this.text = text;
    this.attributes = attributes;
  }

  charAt(index) {
    return new ExtendedChunk(this.text.charAt(index), [...this.attributes]);
  }

  toString() {
    return this.text;
  }

  get length() {
    return this.text.length;
  }

  clone() {
    return new ExtendedChunk(this.text, [...this.attributes]);
  }

  toDebugString() {
    return `'${this.text}' ${JSON.stringify(this.attributes)}`;
  }
}

export class ExtendedString {
  constructor(init = null) {
    if (typeof init === "string") {
      this.chunks = [new ExtendedChunk(init)];
    } else if (Array.isArray(init)) {
      this.chunks = init;
    } else {
      this.chunks = [];
    }
  }

  toString() {
    return this.chunks.map((chunk) => chunk.text).join("");
  }

  toDebugString() {
    return this.toDebugValues().join(" + ");
  }

  toDebugValues() {
    return this.chunks.map(chunk => chunk.toDebugString());
  }

  valueOf() {
    return this.toString();
  }

  isEqual(str) {
    return this.toString() === str;
  }

  match(regexp) {
    return this.toString().match(regexp);
  }

  get stringValue() {
    return this.toString();
  }

  static diffComparator(left, right) {
    const result = left.toString() == right.toString();
    return result;
  }

  get(index) {
    for (let i = 0; i < this.chunks.length; i++) {
      const chunk = this.chunks[i];
      const length = chunk.length;
      if (index < length) {
        return chunk.charAt(index);
      }
      index -= length;
    }
    return null;
  }

  append(str) {
    str.chunks.forEach(chunk => {
      this.chunks.push(chunk.clone());
    });
  }

  get length() {
    let result = 0;
    //__debug("chunks",this.chunks);
    this.chunks.forEach((chunk) => {
      result += chunk.length;
    });
    return result;
  }

  compact() {
    // return this;
    for (let i = 0; i < this.chunks.length - 1; i++) {
      const chunk = this.chunks[i];
      const nextChunk = this.chunks[i + 1];
      if (JSON.stringify(chunk.attributes)==JSON.stringify(nextChunk.attributes)) {
        __debug("merge chunk", chunk.text, "+", `'${nextChunk.text}'`);
        chunk.text += nextChunk.text;
        this.chunks.splice(i + 1, 1);
        i--;
        continue;
      }
    }
    return this;
  }

  addTag(tag, attribs) {
    // __debug("AddTag", tag, attribs);
    this.chunks.forEach(chunk => {
      chunk.attributes.push([tag, attribs]);
    });
  }

  tokenize() {
    let buffer = new ExtendedString([]);
    const result = [];
    //__debug("tokenize", this, "length is", this.length);
    for (let i = 0; i < this.length; i++) {
      const char = new ExtendedString([this.get(i)]);
      const text = char.toString();
      const ws = text == " " || text == "\n" || text == "." || text == "," || text == "!" || text == "?";
      __debug("char->", text, "ws?", ws);
      buffer.append(char);
      if (ws) {
        __debug("WS", char, text, ws);
        __debug_table(buffer.toDebugValues());
        const newBuffer = buffer.compact();
        __debug_table(newBuffer.toDebugValues());
        result.push(newBuffer);
        buffer = new ExtendedString([]);
      }
    }
    if (buffer.length > 0) {
      result.push(buffer.compact());
    }
    __debug("tokens", result);
    __debug_table(result.map(a=>[a.stringValue, a.length, a.toDebugString()]));//,['stringValue', 'length']);
    return result;
  }

  static fromArray(array) {
    const result = new ExtendedString();
    array.forEach(item => result.append(item));
    return result;
  }

  static parseHTML(string) {
    const stack = [];
    const result = new ExtendedString();
    const parser = new htmlparser2.Parser(
      {
        onopentag(name, attribs) {
          stack.push([name, attribs]);
        },
        ontext(text) {
          const chunk = new ExtendedChunk(text, [...stack]);
          result.append(new ExtendedString([chunk]));
        },
        onclosetag(tagname) {
          stack.pop();
        },
      },
      { decodeEntities: true },
    );

    parser.write(string);
    parser.end();

    // __debug("parsedHTML",result);

    return result;
  }

  toHTML() {
    //__debug("chunks",this.chunks);
    let result = "";
    const openedAttr = [];

    const extraChunk = [new ExtendedChunk("")];
    const virtualChunks = [...this.chunks, ...extraChunk];

    // __debug("toHTML chunks",virtualChunks);

    const attrSs = function(attr) {
      let at = Object.entries(attr[1])
      //.filter(([key, value]) => !key.startsWith('$'))
        .map(([key, value]) => `${key}="${value}"`)
        .join(" ");
      if (at != "") {
        at = ` ${at}`;
      }
      return at;
    };

    for (let i = 0; i < virtualChunks.length; i++) {
      const chunk = virtualChunks[i];
      let index = 0;

      // __debug("opened:",openedAttr,"chunk",i,chunk);

      if (openedAttr.length > chunk.attributes.length) {
        for (let j = openedAttr.length - 1; j >= chunk.attributes.length; j--) {
          result += `</${openedAttr[j][0]}>`;
        }
        const numPop = openedAttr.length - chunk.attributes.length;
        for (let j = 0; j < numPop; j++) {
          openedAttr.pop();
        }
      }
      chunk.attributes.forEach((attr) => {
        const attrEq =
          JSON.stringify(attr) == JSON.stringify(openedAttr[index]);
        // __debug("index",index,attr,"opened[index]=",openedAttr[index],"eq?",attrEq);
        if (index >= openedAttr.length) {
          //__debug("> ADD new attr", index, attr);
          const at = attrSs(attr);
          result += `<${attr[0]}${at}>`;
          openedAttr.push(attr);
        } else if (attrEq) {
          // __debug("> KEEP atr",index);
          // do nothing
        } else {
          for (let j = openedAttr.length - 1; j >= index; j--) {
            // __debug("CLOSE OLD attr", j, openedAttr[j])
            result += `</${openedAttr[j][0]}>`;
          }
          openedAttr.splice(index);

          //__debug("> PUSH rep attr",index,attr);
          const at = attrSs(attr);
          result += `<${attr[0]}${at}>`;
          openedAttr.push(attr);
        }
        index += 1;
      });
      result += chunk.text;
    }
    return result;
  }

  static rawDiff(left, right) {
    const leftArr = left.tokenize();
    // __debug("left tokens:", leftArr.length, leftArr);
    const rightArr = right.tokenize();
    // __debug("right tokens:", rightArr.length, rightArr);
    return Diff.diffArrays(leftArr, rightArr, {
      comparator: ExtendedString.diffComparator,
    });
  }
}
