const { createJSONQuery } = require("../lib/json");

function findMissingSummary() {

  const element = document.querySelector(".ldjs-needs-summary");
  if (!element) {
    return;
  }

  const url = element.getAttribute("data-url");
  const pageId = element.getAttribute("data-page-id");
  const length = element.getAttribute("data-length");

  const data = {
    summary: {
      page_id: pageId,
      length: length
    }
  };

  fetch(url, createJSONQuery(data))
    .then(response => {
      if (!response.ok) {
        if (response.status == 429) {
          return response.json().then(jsonData => {
            const retryAfter = jsonData.timeout;
            console.warn(`429 error, try again in ${retryAfter}s`);
            setTimeout(() => findMissingSummary(), retryAfter * 1000);
            throw new Error("429");
            return;
          });
        }
        throw new Error(`HTTP error! Status: ${response.status}`);

      }
      return response.json();
    })
    .then(result => {
      const pElement = document.createElement("p");
      pElement.textContent = result.summary;
      element.replaceWith(pElement);
      findMissingSummary();
    })
    .catch(error => {
      console.error("Error:", error);
    });
}

export function startWatchingSummaries() {
  document.addEventListener("DOMContentLoaded", () => {
    findMissingSummary();
  });
}
