function htmlStringToDOM(htmlString) {
  // Create a temporary div element
  const tempDiv = document.createElement("div");

  // Set the innerHTML of the temporary div to the HTML string
  tempDiv.innerHTML = htmlString;

  // Return the first child (or children, depending on your needs)
  return tempDiv.firstChild; // Use firstChild to get the single element; use tempDiv.children to get all children
}

/*
<div class="field string optional page_title">
  <label class="string optional" for="page_title">Title</label>
  <input class="string optional" type="text" value="ćma ćpa" name="page[title]" id="page_title">
</div>
*/

function contentTag(name, text = null, attribs = {}) {
  const element = document.createElement(name);

  element.textContent = text;

  for (const [key, value] of Object.entries(attribs)) {
    if (value != null && value != undefined) {
      element.setAttribute(key, value);
    }
  }

  return element;
}
function nameToId(name) {
  return name.replace(/[\[\]]/g, "_");
}
function mergeHashes(hash1, hash2) {
  return { ...hash1, ...hash2 };
}
function optionalWrapper(classes, elem) {
  if (classes != null && classes != "") {
    const parent = divTag(classes);
    parent.appendChild(elem);
    return parent;
  }
  return elem;
}
function typicalInputField(field, item, index, value, field_id, field_name, array, extra_fields = {}, extra_wrapper = null) {
  if (!array) {
    let options = { "id": field_id, "type": field, "value": value, "name": field_name };
    options = mergeHashes(options, extra_fields);
    return optionalWrapper(extra_wrapper, contentTag("input", null, options));
  }

  const createFnAndButton = function(val) {
    let options =  { "style":"width:400px", "id": field_id, "type": field, "value": val, "name": `${field_name}[]` };
    options = mergeHashes(options, extra_fields);
    const input = optionalWrapper(extra_wrapper, contentTag("input", null, options));
    const button = contentTag("div", "remove", { "class":"ui small negative button" });
    const line = divTag("ui inline field");
    line.appendChild(input);
    line.appendChild(button);
    button.addEventListener("click", () => {
      line.remove();
    });
    return line;
  };
  const ret = divTag();
  const items = divTag();
  if (value) {
    if (!Array.isArray(value)) {
      value = [value];
    }
    value.forEach(vv => {
      items.appendChild(createFnAndButton(vv));
    });
  }
  items.appendChild(createFnAndButton(""));
  const button = contentTag("div", "add more", { "class": "ui small button" });
  button.addEventListener("click", ()=>{
    items.appendChild(createFnAndButton(""));
  });
  ret.appendChild(items);
  ret.appendChild(button);
  return ret;
}

function inputString(item, index, value, field_id, field_name, array) {
  return typicalInputField("text", item, index, value, field_id, field_name, array);
}

function inputText(item, index, value, field_id, field_name, array) {
  return contentTag("textarea", value, { "id": field_id, "name": field_name });
}

function inputNumber(item, index, value, field_id, field_name, array) {
  return typicalInputField("number", item, index, value, field_id, field_name, array);
}

function inputDate(item, index, value, field_id, field_name, array) {
  return typicalInputField("text", item, index, value, field_id, field_name, array, {}, "ui calendar ld-simple-calendar-input");
}

function divTag(classes = "") {
  return contentTag("div", null, { "class": classes });
}

function inputEnum(item, index, value, field_id, field_name, array) {
  if (array && !Array.isArray(value)) {
    value = [value];
  };
  const myValue = value;
  const fieldset = contentTag("fieldset", null, { "class":"ld-fake-fieldset" });
  //  const hiddenInput = contentTag("input", null, { "type":"hidden", "name": field_name, "value":"" });
  //  fieldset.appendChild(hiddenInput);
  const type = array ? "checkbox" : "radio";
  const real_field_name = (index) => array ? `${field_name}[${index}]` : field_name;
  item.enumValues.forEach((value, index) => {
    const sub_id = `${field_id}_${value}`;
    const input = contentTag("input", null, { "id": sub_id, "type":type, "name":real_field_name(index), "value": value });
    //var q= 0;
    // if (value == "kotek") {throw new Error("kupaa");}
    if (myValue && myValue.includes(value)) {
      input.checked = true;
      //     q=100;

    }//+"["+myValue+"]"+q
    const label = contentTag("label", value, { "for": sub_id });
    const ui = divTag(`ui ${!array ? "radio " : ""}checkbox`);
    ui.appendChild(input);
    ui.appendChild(label);
    const field = divTag("ui field");
    field.appendChild(ui);
    fieldset.appendChild(field);
  });
  return fieldset;
}

function createMetadataInput(item, index, value, field_id, field_name) {
  const functions = {
    "string": inputString,
    "number": inputNumber,
    "enum": inputEnum,
    "date": inputDate,
    "reference": inputString,
    "optional_reference": inputString,
    "text": inputText,
  };
  const fn = functions[item.type];
  if (fn) {
    return fn(item, index, value, field_id, field_name, item.array);
  }
  return null;
}

function createMetadataField(item, index, value) {
  const field = divTag("metadata field");
  const field_name = `page[metadata][${item.key}]`;
  const field_id =  nameToId(field_name);
  const label = contentTag("label", item.name, { "for":field_id });
  field.appendChild(label);
  const input = createMetadataInput(item, index, value, field_id, field_name);
  if (input) {
    field.appendChild(input);
  }
  return field;
  //return htmlStringToDOM(`<p>${item.key} - ${index} - ${value}</p>`);
}

export function startMetadata() {
  const controller = document.querySelector(".ld-metadata-controller");
  if (!controller) {
    return;
  }

  const template = window.metaDataTemplate ?? [];
  const values = window.metaDataValues ?? {};

  template.forEach((item, index) => {
    controller.appendChild(createMetadataField(item, index, values[item.key]));
  });

  console.log("uhu", $(".ld-simple-calendar-input"));
  $(".ld-simple-calendar-input").calendar({ type: "date", firstDayOfWeek: 1 });
}
