export function checkForToast() {
  document.addEventListener("DOMContentLoaded", () => {
    const element = document.querySelector(".ldjs-toast-controller");
    if (!element) {
      return;
    }
    const notice = element.getAttribute("data-toast");
    $.toast({ message: notice });
  });
}
