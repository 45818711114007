import Quill from "quill";

export function startHandlingHelperEditor() {
  document.addEventListener("DOMContentLoaded", () => {
    const controller = document.querySelector(".ld-helper-editing-editor");
    if (!controller) {
      return;
    }

    const quill = new Quill(controller, {
      theme: "snow"
    });

    const form = controller.closest("form");

    form.addEventListener("submit", function(event) {
    // Create a new input element
      const input = document.createElement("input");

      // Set the input attributes
      input.type = "hidden";
      input.name = "custom_html";
      input.value = quill.getHtml();

      // Append the input to the form
      form.appendChild(input);

      return true; // This line is mostly redundant as the form will submit anyway
    });

  });
}

