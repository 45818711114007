import consumer from "./consumer";

export function createChatChannel(page_id) {
  return {
    object: null,
    callbacks: {
      onConnect: null,
      onMessage: null
    },
    send: function(msg) {
      this.object.send({ body: msg });
    },
    onConnect(callback) {

      const channel = this;

      this.callbacks.onConnect = callback;

      this.object = consumer.subscriptions.create({ page_id: page_id, channel: "ChatChannel" }, {
        received(data) {
          console.log("Received websocket: ", data);
          if (channel.callbacks.onMessage) {
            channel.callbacks.onMessage(channel, data.body, data.user, data.created_at);
          }
        },
        connected() {
          console.log("WS connected");
          if (channel.callbacks.onConnect) {
            channel.callbacks.onConnect(channel);
          }
        },

        disconnected() {
          console.log("WS disconnected");
        },
      });

      return this;
    },
    onMessage(callback) {
      this.callbacks.onMessage = callback;
      return this;
    }
  };
}
