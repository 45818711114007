const { makeEditor, isInEmptyParagraph } = require("./editor");
const { reorderSidebarComments } = require("./comments");
import Quill from "quill";
import { escapeHTML } from "../lib/string.js";

function addHttp(url) {
  if (!/^https?:\/\//i.test(url)) {
    return `http://${url}`;
  }
  return url;
}

$(function() {

  $(".ld-html-external-link-button").on("click", function(event) {
    window.currentQuill = window.globalQuill;
    $(".ld-link-modal").modal({
      inverted: true
    }).modal("show");
  });

  $(".ld-html-summarize-button").on("click", function(event) {
    window.currentQuill = window.globalQuill;
    $(".ld-summarize-modal").modal({
      inverted: true
    }).modal("show");
  });

  $(".ld-page-edit-button").on("click", function(event) {
    document.querySelector("#wiki-content").classList.add("ld-page-editing-overlay");
    const button = $(this);
    const topForm = button.parents("form");
    topForm.addClass("ld-active");
    const submit = $(this).siblings(".ld-page-submit-button");
    const form = button.parents(".ld-jseditor-form");
    const content = form.find(".ld-html-content");
    const template = document.querySelector("#ld-toolbar-template");
    const clon = template.content.cloneNode(true);
    const headerInput = form.find(".ld-header-input");
    headerInput.addClass("ui");
    headerInput.addClass("sticky");
    const newRow = document.createElement("div");
    // newRow.classList.add('sixteen', 'wide', 'field');
    headerInput[0].appendChild(clon);
    // newRow.appendChild(clon);
    const quill = makeEditor(content);
    button.hide();
    submit.show();
    const header = form.find(".ld-header");
    header.hide();
    headerInput.show();
  });
  $(".ld-standalone-editor").each(function(index, item) {
    makeEditor($(item));
  });
  $("#ld-link-button").on("click", function(event) {
    event.preventDefault();
    const form = $(this).parents(".ui.modal").find("form");
    const page_id = form.find("select[name=\"page_id\"]").val();
    const quill = window.currentQuill;
    quill.format("internal_link", page_id);
  });
  $("#ld-summarize-button").on("click", function(event) {
    event.preventDefault();
    const form = $(this).parents(".ui.modal").find("form");
    const url = $(this).attr("data-summarize-url");
    const length = form.find("input[name=\"length\"]").val();
    const focus = form.find("input[name=\"focus\"]").val();
    const page_id = form.find("select[name=\"page_id\"]").val();
    const quill = window.currentQuill;
    quill.enable(false);
    const dom = $(quill.root);
    //console.log("dom", dom);
    const dom_form = dom.parents("form");
    //,dom_form,dom_loader);
    const dom_loader = dom_form.find(".ld-editor-loader")[0];
    dom_loader.classList.add("active");
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        summary: {
          page_id: page_id,
          length: length,
          focus: focus,
        },
      }),
    })
      .then(response => response.json())
      .then(result => {
        console.log("success!", result);
        let summary = result.summary;
        summary = summary.replace(/\n/g, " ");
        summary = summary.trim();

        const quill = window.currentQuill;
        const insertNl = !isInEmptyParagraph(quill);
        summary = `${summary}\n`;
        const range = quill.getSelection(true);
        const embed_data = {
          id: page_id,
          target: length,
          focus: focus,
          title: result.title,
        };
        let mod = 0;

        if (insertNl) {
          quill.insertText(range.index, "\n", Quill.sources.API);
          mod = 1;
        }

        quill.insertText(range.index + mod, summary, { summary_para: embed_data }, Quill.sources.API);
        quill.enable(true);
        dom_loader.classList.remove("active");
      })
      .catch((error) => {
        console.error("Error:", error);
      });

  });

  $(".ui.dropdown:not(.ld-page-id)").dropdown();

  const url = `${$(".ld-page-id").attr("data-search-url")}?query={query}`;

  $(".ld-page-id").dropdown({
    saveRemoteData: false, cache:false,
    apiSettings: { url: url, saveRemoteData: false, cache:false,
      onResponse: function(input) {
        const response = {
          results: []
        };

        input.items.forEach(function(item) {
          response.results.push({
            name: escapeHTML(item.title),
            description: escapeHTML(item.description),
            descriptionVertical: true,
            value: `page://${item.id}`
          });
        });

        return response;
      }
    }
  });

  reorderSidebarComments();

  window.addEventListener("load", function() {
    setTimeout(function() {
      // Your code here
      reorderSidebarComments();
    }, 1000);
  });

  $(".ld-icon-button").popup();

  $(document).on("click", ".ld-edit-membership-button", function(event) {
    const id = $(this).attr("data-membership-id");
    $(`.ld-show-membership-row[data-membership-id=${id}]`).hide();
    $(`.ld-edit-membership-row[data-membership-id=${id}]`).show(200);
  });

  $(document).on("click", ".ld-color-box", function(event) {
    const quill = window.globalQuill;
    const value = $(this).attr("data-color");
    const mode = window.globalColorMode;
    //console.log("qui",quill,"->",value,"(",mode);
    quill.format(mode, value);
    $(this).popup("hide all");
  });

  $(".ld-link-button")
    .popup({
      onShow: () => {
        $(".ld-internal-link-field").val("");
        window.globalQuillSelection = window.globalQuill.getSelection();
      },
      onVisible: () => {
        $(".ld-internal-link-field").focus();
      },
      on: "click",
      popup: ".ld-link-popup",
      delay: {
        show: 100,
        hide: 500
      }
    });

  const insertInternalLink = () => {
    const quill = window.globalQuill;
    const value = $(".ld-internal-link-field").val();
    const linkValue = addHttp(value);

    const selection = quill.getSelection() || window.globalQuillSelection;

    if (!selection) {
      const length = quill.getLength();
      quill.insertText(length, value);
      quill.setSelection(length, value.length);
    } else if (selection && selection.length === 0) {
      const index = selection.index;
      quill.insertText(index, value);
      quill.setSelection(index, value.length);
    }

    quill.format("link", linkValue);
    $(document).popup("hide all");
  };

  $(document).on("keydown", ".ld-internal-link-field", event => {
    if (event.key === "Enter") {
      insertInternalLink();
      event.preventDefault();
    }
  });

  $(".ld-internal-link-button").on("click", function() {
    insertInternalLink();
  });

  $(".ld-color-button")
    .popup({
      on: "click",
      popup: $(".ld-color-popup"),
      hoverable: true,
      onShow: function(parent) {
        window.globalColorMode = $(parent).attr("data-format");
      },
      delay: {
        show: 100,
        hide: 500
      }
    });

  const stickyToolbar = $(".ld-parent-html-toolbar-container.ui.sticky");
  if (stickyToolbar.length) {
    stickyToolbar.sticky({ context: ".ld-sticky-container" });
  }
  const stickyToc = $(".ld-toc.ui.sticky");
  if (stickyToc.length) {
    console.log("stick toc");
    stickyToc.sticky({ context: ".ld-toc-sticky-container" });
  }

  // $('.ld-simple-calendar-input').calendar();
});
