const Diff = require("diff");
const { createJSONQuery } = require("../lib/json");
import { marked } from "marked";
import { ExtendedString } from "../lib/string-attribs";
const beautify = require("simply-beautiful");

function textToHardParagraphsInDOM(element) {
  const text = element.innerHTML;
  const paragraphs = text.split("\n\n").map(para => `<p>${para}</p>`).join("");
  element.innerHTML = paragraphs;
}

const DEBUG = !false;

function __debug(...arg) {
  if (DEBUG) {
    console.log(...arg);
  }
}
function __debug_table(...arg) {
  if (DEBUG) {
    console.table(...arg);
  }
}

function removeEmptyTags(node) {
  let removed = false;

  function removeTags() {
    let tagsRemoved = false;
    const elements = node.querySelectorAll("b, i, u, em, strong");

    elements.forEach(el => {
      if (el.innerHTML.trim() === "") {
        el.parentNode.removeChild(el);
        tagsRemoved = true;
        removed = true;
      }
    });

    return tagsRemoved;
  }

  while (removeTags()) {
    ;
  }

  return removed;
}

function allChangeClasses() {
  const element = document.querySelector("#wiki-content");
  const childElements = element.querySelectorAll("*");
  const classList = Array.from(childElements)
    .flatMap(el => Array.from(el.classList))
    .filter(cls => cls.startsWith("jsd-change-"));
  return new Set(classList);
}

const BLOCK_TAGS = ["p", "ul", "ol", "li", "img", "br"];
const DYNAMIC_BLOCK_TAGS = {};

const BLOCK_META_TAG = "__METABLOCK__";

const META_START_CLASS = "__{TAG}__START__";
const META_END_CLASS = "__{TAG}__END__";

const META_INSIDE = "##!";

function clearAllDivContents(html) {
  const node = document.createElement("div");
  node.innerHTML = html;
  const divs = node.querySelectorAll(`div.${BLOCK_META_TAG}`);
  divs.forEach(div => {
    div.innerHTML = ` ${META_INSIDE} `; // Clear the content inside each div
  });
  return node.innerHTML;
}

function metaStartClass(tag) {
  return `${META_START_CLASS.replace("{TAG}", tag)} ${BLOCK_META_TAG}`;
}

function metaEndClass(tag) {
  return `${META_END_CLASS.replace("{TAG}", tag)} ${BLOCK_META_TAG}`;
}

function metaStart(tag) {
  return `<div class="${metaStartClass(tag)}"> ${META_INSIDE} </div>`;
}

function metaEnd(tag) {
  return `<div class="${metaEndClass(tag)}"> ${META_INSIDE} </div>`;
}

function addDynamicTag(tag, catchTag, catchAttr) {
  const tg = `${tag} ${catchAttr}`;
  if (!DYNAMIC_BLOCK_TAGS.hasOwnProperty(tg)) {
    const len = Object.keys(DYNAMIC_BLOCK_TAGS).length;
    const repl = metaStart(`TAG${len}`);
    DYNAMIC_BLOCK_TAGS[tg] = [len, repl, tag, catchAttr];
  }
  return DYNAMIC_BLOCK_TAGS[tg][1];
}

function paragraphToSeparators(text) {
  BLOCK_TAGS.forEach(tag => {
    text = text
      .replace(new RegExp(`<\/${tag}>`, "gi"), `\n${metaEnd(tag)}\n`)
      .replace(new RegExp(`<(${tag})(\s*.*?)>`, "gi"), function(match, catchTag, catchAttr, offset, string) {
        __debug("Dynamic tag:", match, "::", tag, catchTag, catchAttr, "<>", offset);
        return addDynamicTag(tag, catchTag, catchAttr);
        // return `${metaStart(tag)}\n`;
      });
  });
  __debug("dynamic table");
  __debug_table(DYNAMIC_BLOCK_TAGS);
  return text;
}

function separatorsToParagraphs(text) {
  // DYNAMIC_BLOCK_TAGS.forEach(xtag => {
  Object.entries(DYNAMIC_BLOCK_TAGS).forEach(([key, xtag]) => {

    const tag = xtag[2];
    const repl = xtag[1];
    const attrs = xtag[3];
    text = text
      .replaceAll(repl, `<${tag} ${attrs}>`)
      .replaceAll(metaEnd(tag), `</${tag}>`);
  });
  return text;
}

function normalizeHTML(html) {
  html = html.trim();
  const node = document.createElement("div");
  node.innerHTML = html;
  __debug("normalize");
  __debug(html);
  const result = node.innerHTML;
  __debug(result);
  return result;
}

function stripWhitespace(html) {
  return normalizeHTML(html);
  return html;
  return html.replace(/\s*(<[^>]+>)\s*/g, "$1").replace(/\s+/g, " ").trim();
}

function updateDiffCount() {
  const count = allChangeClasses().size;

  document.querySelectorAll(".ld-remaining-diffs").forEach(item => item.textContent = count);

  const approve = document.querySelector(".ld-approve-remaining-and-merge-button");
  const reject = document.querySelector(".ld-reject-remaining-and-merge-button");
  const merge = document.querySelector(".ld-merge-button");

  if (count == 0) {
    approve.classList.add("ld-hidden");
    reject.classList.add("ld-hidden");
    merge.classList.remove("ld-hidden");
  } else {
    approve.classList.remove("ld-hidden");
    reject.classList.remove("ld-hidden");
    merge.classList.add("ld-hidden");
  }
}

function stripAllTags(text) {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = text;
  return (tempDiv.textContent || tempDiv.innerText || "").trim();
}

function debugDiffData(info, source, target) {
  __debug(`Debug: ${info} source:`);
  __debug(source);
  __debug(`Debug: ${info} target:`);
  __debug(target);
  __debug("------");
}

function beautifyHTML(html) {
  return beautify.html(html);
}

function prepareHTML(text) {
  return beautifyHTML(paragraphToSeparators(stripWhitespace(text)));
}

function restoreHTML(text) {
  __debug("Restore:");
  __debug(text);
  text = clearAllDivContents(text);
  let result = separatorsToParagraphs(text);
  result = beautifyHTML(result);
  __debug("->");
  __debug(result);
  return result;
}

function calculateDiff(source, target) {
  debugDiffData("initial", source.innerHTML, target.innerHTML);

  const parsedSource = ExtendedString.parseHTML(prepareHTML(source.innerHTML));
  const parsedTarget = ExtendedString.parseHTML(prepareHTML(target.innerHTML));

  debugDiffData("parsed", parsedSource.toHTML(), parsedTarget.toHTML());

  const diff = ExtendedString.rawDiff(parsedSource, parsedTarget);

  target.innerHTML = "";

  let lastDelIndex = null;
  let changeId = 1;

  // __debug("found", diff.length, "diffs");

  const result = new ExtendedString();

  for (let i=0; i < diff.length; i++) {

    if (diff[i].added && diff[i + 1] && diff[i + 1].removed) {
      const swap = diff[i];
      diff[i] = diff[i + 1];
      diff[i + 1] = swap;
    }

    const node = null;
    const nextNode = diff[i + 1];

    let changeClass = `jsd-change-${changeId}`;

    const extStr = ExtendedString.fromArray(diff[i].value);
    const plainText = extStr.toString();

    // __debug("change", i, "raw", diff[i], "text", plainText);

    if (diff[i].removed) {
      let classes = changeClass;
      if (nextNode && nextNode.added) {
        classes += " jsd-compacted-del";
      }
      extStr.addTag("del", { class: classes });
      changeId++;
      lastDelIndex = i;
    } else if (diff[i].added) {
      if (lastDelIndex == i - 1) {
        changeId -= 1;
        changeClass = `jsd-change-${changeId}`;
      }
      extStr.addTag("ins", { class: changeClass });
      changeId++;
    } else if (diff[i].chunkHeader) {
      console.warn("WTF", diff[i]);
    }
    // __debug("i=", i, "extStr=", extStr);
    result.append(extStr);
  }

  let processedHTML = result.toHTML();

  __debug("Result->");
  __debug(result);
  __debug("GENERTED:");
  __debug(processedHTML);
  __debug("-----------");

  processedHTML = restoreHTML(processedHTML);

  __debug("restored:");
  __debug(processedHTML);
  __debug("-----------");

  target.innerHTML = processedHTML;
  source.innerHTML = processedHTML;
}

export function createEditingDiff() {
  const controller = document.querySelector(".ld-diff-controller");
  if (!controller) {
    return;
  }

  const url = controller.getAttribute("data-url");
  const editingStyle = controller.getAttribute("data-style");
  const baseRevision = controller.getAttribute("data-base-revision");

  const source = document.querySelector(".ld-diff-source");
  const target = document.querySelector(".ld-diff-target");

  if (!source || !target) {
    console.error("Source or target elements not found.");
    return;
  }

  calculateDiff(source, target);

  $(".ld-diff-target ins, .ld-diff-target del").popup({
    popup: ".ld-editing-target-popup",
    on: "click",
    lastResort: true,
    onShow: function(context) {
      const changeClass = Array.from(context.classList).find(cls => /^jsd-change-/.test(cls));
      const nodes = document.querySelector(".ld-diff-target").querySelectorAll(`.${changeClass}`);
      const clonedNodes = Array.from(nodes).map(node => node.cloneNode(true));
      const popup = this[0];
      const content = popup.querySelector(".ldjs-diff-content");
      content.innerHTML = "";

      const delContainer = document.createElement("div");
      delContainer.classList.add("ld-del-container");
      const insContainer = document.createElement("div");
      insContainer.classList.add("ld-ins-container");

      // Group the nodes by their tag
      clonedNodes.forEach(node => {
        if (node.tagName.toLowerCase() === "del") {
          delContainer.appendChild(node);
        } else if (node.tagName.toLowerCase() === "ins") {
          insContainer.appendChild(node);
        }
      });

      content.append(delContainer, insContainer);
      // content.append(...clonedNodes);
    },
    onVisible: function(context) {
    }
  });
  const popup = document.querySelector(".ld-editing-target-popup");
  const processChange = function(changeClass, approve) {
    const okAction = approve ? "ins" : "del";
    const badAction = approve ? "del" : "ins";
    document.querySelectorAll(`.ld-diff ${okAction}.${changeClass}`).forEach(item => {
      const newNode = document.createElement("span");
      newNode.classList.add("ld-approved-change");
      newNode.textContent = item.textContent;
      item.replaceWith(newNode);
    });
    document.querySelectorAll(`.ld-diff ${badAction}.${changeClass}`).forEach(item => {
      item.remove();
    });
  };
  const generateAction = function(approve) {
    return function() {
      const change = popup.querySelector("ins, del");
      const changeClass = Array.from(change.classList).find(cls => /^jsd-change-/.test(cls));
      processChange(changeClass, approve);
      $(document).popup("hide all");
      document.querySelector(".ldjs-diff-content").innerHTML = "";
      updateDiffCount();
    };
  };
  const apply = generateAction(true);
  const discard = generateAction(false);
  popup.querySelector(".ld-apply-edit-button").addEventListener("click", apply);
  popup.querySelector(".ld-discard-edit-button").addEventListener("click", discard);
  updateDiffCount();

  const applyMergeButton = document.querySelector(".ld-approve-remaining-and-merge-button");
  const rejectMergeButton = document.querySelector(".ld-reject-remaining-and-merge-button");
  const mergeButton = document.querySelector(".ld-merge-button");
  const generateMassEdit = function(approve) {
    return function() {
      __debug("all changes:", allChangeClasses());
      allChangeClasses().forEach(changeClass => {
        processChange(changeClass, approve);
      });
      document.querySelector(".ldjs-diff-content").innerHTML = "";
      updateDiffCount();
    };
  };
  const generateMerge = function() {
    document.querySelector(".ld-merge-loader").classList.add("active");
    mergeButton.remove();
    document.querySelector(".ld-diff-source").remove();
    document.querySelectorAll(".ld-approved-change").forEach(node => {
      node.replaceWith(document.createTextNode(node.textContent));
    });
    const node = document.querySelector(".ld-diff-target");
    removeEmptyTags(node);

    const data = {
      page: {
        html: node.innerHTML,
        change_description: `Editing: ${editingStyle}`,
        special_action: "editing",
        base_revision: baseRevision,
        // autosave: true
      }
    };

    const query = createJSONQuery(data, "PATCH");

    fetch(url, query)
      .then(response => {
        __debug("response: ", response);
        if (response.redirected) {
          window.location.href = response.url;
        }
        return response.json();
      })
      .then(result => {
        __debug("result->", result);
        window.location.href = result.url;
      })
      .catch(error => {
        console.error("Error:", error);
      });
  };
  applyMergeButton.addEventListener("click", generateMassEdit(true));
  rejectMergeButton.addEventListener("click", generateMassEdit(false));
  mergeButton.addEventListener("click", generateMerge);
}
