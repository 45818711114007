import { timeInFutureInWords, timeAgoInWords } from "../lib/date";

export function startUpdatingDate() {

  const interval = 1000;

  const updateDatestamps = function() {

    document.querySelectorAll(".ldjs-datetime").forEach(function(node, index) {
      if (node.getAttribute("data-future") == "true") {
        node.innerText = timeInFutureInWords(node.getAttribute("data-date"));
      } else {
        node.innerText = timeAgoInWords(node.getAttribute("data-date"));
      }
    });

    setTimeout(updateDatestamps, interval);
  };

  setTimeout(updateDatestamps, interval);
}
